@media screen and (min-width: 981px) {
    .post>header .title.rides-back-title {
        padding: 0 3em 3.3em 3em !important;
    }
}

.rides-back-link {
    height: 3.75em;
    margin-left: 1em;
    padding-top: 1.5em;
    text-decoration: none!important;
    font-family: "Raleway", Helvetica, sans-serif!important;
    font-size: 0.75em!important;
    font-weight: 400!important;
}

@media screen and (min-width: 981px) {
    .rides-back-link {
        height: 5em;
        margin-left: 2em;
    }
}

.image-gallery-thumbnails::-webkit-scrollbar{
    width: 10px;
    height: 8px;
}

.image-gallery-thumbnails::-webkit-scrollbar-track-piece{
    background-color: transparent;
}

.image-gallery-thumbnails::-webkit-scrollbar-thumb{
    background-color: #CBCBCB;
    /*outline: 2px solid #FFF;*/
    outline-offset: -2px;
    /*border: .1px solid #B7B7B7;*/
    border-radius: 20px;
}

.image-gallery-thumbnails::-webkit-scrollbar-thumb:hover{
    background-color: #909090;
}

/*@media screen and (min-width: 767px) {*/
.image-gallery-thumbnails {
    overflow: auto!important;
}
/*}*/

.home-image {
    width: calc(100% + 6em);
    height: 300px;
    object-fit: cover;
    margin-left: -3em;
    margin-right: -3em;
    margin-top: -3em;
    /*margin-right: 10em;*/
    position: relative;
    /*offset-before: -3em;*/
}

@media screen and (max-width: 736px) {
    .home-image {
        width: calc(100% + 3em);
        height: 300px;
        object-fit: cover;
        margin-left: -1.5em;
        margin-right: -1.5em;
        margin-top: -1.5em;
        /*margin-right: 10em;*/
        position: relative;
        /*offset-before: -3em;*/
    }
}

.ride-star {
    color: #fff200;
    transform: scale(1.25);
}

.maps-icon {
    transform: scale(1.5);
}

.maps-button {
    background-color: #f6f6f6 !important;
    height: auto!important;
    position: absolute;
    right: var(--bs-card-img-overlay-padding);
    top: var(--bs-card-img-overlay-padding);
    /*padding: 0!important;*/
    /*padding-left: 1em!important;*/
    /*padding-right: 1em!important;*/
    /*border-radius: 10px!important;*/
    font-family: "Raleway", Helvetica, sans-serif!important;
    font-size: 0.65em!important;
    font-weight: 800!important;
    /* height: 4.8125em; */
    letter-spacing: 0.25em!important;
    line-height: 3em!important;
    padding: 0 1em!important;
    text-align: center!important;
    text-decoration: none!important;
    text-transform: uppercase!important;
    white-space: nowrap!important;
}

.ride-card-image-container {
    position: relative;
    overflow: hidden; /* Ensure the gradient doesn't overflow */
}

.ride-card-image-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65%; /* Adjust the height of the overlay as per your preference */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    pointer-events: none; /* Allows clicking through the overlay */
}

.card-img-overlay {
    position: relative;
}

.ride-card-body {
    bottom: 0;
    position: absolute;
    padding-right: var(--bs-card-img-overlay-padding);
}

.ride-card-body p {
    color: #e7e7e7;
}

.image-gallery-fullscreen-button, .image-gallery-play-button {
    top: 0;
    bottom: unset!important;
    padding: 20px;
}

.image-gallery-thumbnail {
    width: unset!important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: unset!important;
}

@media (max-width: 768px) {
    .image-gallery-thumbnail {
         width: unset!important;
    }
}

[class*="image-gallery-thumbnail-"] {
    height: 69px;
    width: unset;

    /*object-fit: contain;*/
}

.hamburger-link {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.image-gallery-thumbnails-container{
    max-width: 10px
}

.play-button {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
}

.play-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
}

.play-button:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}

.close-video::before {
    content: '✖';
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 20px;
    z-index: 1;
    line-height: .7;
    display: block;
    color: #000000;
}

.video-wrapper {
    position: relative;
    top: -50%;
    /*padding: 33.35% 0; !* 16:9 *!*/
    /*height: 0;*/
}

.video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dropdown {
    position: relative;
    width: min(350px, 100%);
}

/*.dropdown:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    right: 10px;*/
/*    top: 8px;*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-left: 10px solid transparent;*/
/*    border-right: 10px solid transparent;*/
/*    border-top: 10px solid #f00;*/
/*}*/

.dropdown:after {
    margin-top: 15px;
    content: "";
    position: absolute;
    right: 10px;
    top: 3px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #333;
}

.divFixedClass {
    position: fixed;
    top:44px;
    left:0;
    right:0;
    bottom:0;
    min-height: 100%;
    min-width: 100%;
    height: 100vh!important;
    width: 100vw!important;
    z-index: 999;
    background-color: black;
}

.satellite-toggle {
    top: 4em;
    left: .5em;
}

.bike-overlay-toggle {
    top: 5.75em;
    left: .5em;
}

.photos-toggle {
    top: 7.5em;
    left: .5em;
}

.fullscreen-toggle {
    top: 15px;
    right: 10px;
}

.planned-line {
    margin:5px 0;
    width: 55px;
    display: inline-block;
    height:2px;
    background:
            repeating-linear-gradient(to right,red 0,red 5px,transparent 5px,transparent 10px)
    /*5px red then 2px transparent -> repeat this!*/
}
.ridden-line {
    margin:5px 0;
    width: 55px;
    display: inline-block;
    height:2px;
    background:
            repeating-linear-gradient(to right,blue 0,blue 5px)
    /*5px red then 2px transparent -> repeat this!*/
}

p {  margin-top: 0.6em; margin-bottom: 10px!important; }

.menu-hover {
    font-size:25px;
}

.carousel .slide img {
    -o-object-fit: contain;
    object-fit: contain;
    max-height: 300px;  /* change this to whatever you want */
    max-width: 600px;  /* change this to whatever you want */
    width: auto;
    position: center;
    margin: auto;
}

.instagram-image {
    -o-object-fit: contain;
    object-fit: contain;
    max-height: 300px;  /* change this to whatever you want */
    max-width: 600px;  /* change this to whatever you want */
    width: auto;
    position: center;
    margin: 5px;
}

.dog-image {
    max-width: inherit;
    max-height: inherit;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.carousel-root {
    max-width: 750px;
}

.Dropdown-root {
    max-width: 100px;
}

.strava-iframe {
    /*-o-object-fit: contain;*/
    /*object-fit: contain;*/
    display: block;
    height: 550px;
}

.carousel .control-prev.control-arrow:before,
.carousel .control-next.control-arrow:before {
    display: none;
}

.hls {
    -o-object-fit: contain;
    object-fit: contain;
    max-height: 300px;  /* change this to whatever you want */
    /*width: auto;*/
}

/*.strava-embed-root {*/
/*    -o-object-fit: contain;*/
/*    object-fit: contain;*/
/*    max-height: 300px;  !* change this to whatever you want *!*/
/*}*/
